
.fav-trips-background{
  background-color: white;
box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.49);
}

.square-border{
  border: 1px #ec7228;
border-radius: 5px;
background-color: #ec7228 !important;
position: absolute;
left: 98%;
top: -4%;
height: 40px;
width: 40px;
}
.btn-fav-trips{
  width: 151px;
  height: 58px;
  font-size: 20px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: #ec7228 !important;
  color: white !important;
}
.favorite-img-height{
 min-height: 100% !important; 
}

.outfitter-fav-trips{
padding-left: 10%;
padding-right: 10%;
}

.card-fav-trips{
  width: 100%;
}

.btn-fav-trips-results{
  border-color: #ec7228 !important;
  color: #ec7228 !important;
  height: 63px;
width: 254px;
font-weight: bold !important;
font-size: 19px !important;
background-color:rgba(236, 114, 40 ,0.1) !important;
}

@media  (max-width: 1800px) {
  .square-border{
    border: 1px #ec7228;
    border-radius: 5px;
    background-color: #ec7228 !important;
    position: absolute;
    left: 96%;
    top: -4%;
    height: 40px;
    width: 40px;
  }
}

@media   (min-width: 2800px) {
.square-border{
  border: 1px #ec7228;
  border-radius: 5px;
  background-color: #ec7228 !important;
  position: absolute;
  left: 99%;
  top: -4%;
  height: 40px;
  width: 40px;
}
}
.btn-no-border{
  padding: 0 !important;
}
.btn-no-border:focus{
border: none !important;
box-shadow: 0 0 0 0.0rem rgba(0,0,0,0) !important;
}

.favorite-trips-menu-width{
width: 316px !important;
padding: 0 !important;
}
.favorite-trips-bigger-cell{
font-family: "Roboto";
font-size: 15px;
font-weight: bold !important;
height:70px !important;

display: flex;
align-items: center;

}
.favorite-trips-bigger-cell:hover{
background-color: #EC7228 !important;
color: white !important;
}

.trips-tab p:hover {
color: var(--guidexPrimary) !important;
border-bottom: 5px solid var(--guidexPrimary) !important;
padding-bottom: 16px;

padding-right: 3%;
padding-left: 1%;
}
.active-tab {
  color: var(--guidexPrimary) !important;
  border-bottom: 5px solid var(--guidexPrimary) !important;
  padding-bottom: 16px;
  
  padding-right: 3%;
  padding-left: 1%;
  }

.reviews-tab-title{
margin-right: 7% !important;
}
.title-padding{
  margin-top: -.8%;
}
.dropdown-divider {margin: 5 !important;}

.favorite-trip-card-text{
  width: 90% !important;
  word-break: keep-all;
}
.favorite-trip-card-text-responsive{
  width: 90% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-size: 22px !important;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.image-size {
  max-height: 262px;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
}
.btn-responsive{
  width: 216px;
  height: 70px;
  font-size: 24px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: #ec7228 !important;
  color: white !important;
}