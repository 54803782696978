.first-column {
  width: 60%;
  word-wrap: break-word;
}

.guide-text-black {
  color: #222b45;
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.guide-avatar {
  height: 60px;
  width: 60px;
  border-radius: 100%;
}
.captain-text * {
  color: #222b45;
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 30px;
}

.captain-list {
  padding-inline-start: inherit;
}
#tripCapitan {
  background-color: transparent !important;
  border: unset !important;
}
.max-container-overflow {
  max-height: 300px;
  overflow-y: scroll;
}
