.case-0{
background-color: white;
  width: 92% !important;
position: relative;
right: 4%;
text-align: center;
}

.case-0-text{
    font-size: 22px;
    font-weight: bold;
   
}