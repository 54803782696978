.title-terms {
    text-align: center;
    color: #466660;
    font-family: "Montserrat";
    font-size: 36px;
    font-weight: bold;
}

.terms-padding-bottom {
    padding-bottom: 10%;
}