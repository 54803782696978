.download-icon{
    height: 70px;
    width: 66px;
    opacity: 0.5;
   
}
.drag-text{
    color: #222B45 !important;
  font-family: "Roboto";
  font-size: 22px;
  font-weight: bold;
}

.btn-add-image{
    color:white !important;
    font-family: "Roboto" !important;
    font-weight: bold !important;
    background-color: var(--guidexPrimary) !important;
    height: 48px !important;
  width: 248px !important;
}

.row-images{
  display: inline-flex;
}