
.CalendarDay__selected_span {
  background: #ec7228; 
  color: white; 
  border: 1px solid; 
}

.CalendarDay__selected {
  background:#ec7228;
  color: white;
}

.CalendarDay__selected:hover {
  background: #ec7228;
  color: white;
}


.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #ec7228;
  color: white;
}
.CalendarDay__selected_span:hover{
  background: #222B45;
}

.DateInput_input__focused{
  border-bottom: 2px solid #222B45;
}
.DateRangePicker{
  width: 100%;
  
}
.DateRangePickerInput{
  width: 27%;
  background: #f7f9fc !important;

}
.DateInput_1{
  width: 45% !important;
  text-align:  right;
  background: #f7f9fc !important;
} 
.DateInput_input{
  background: #f7f9fc !important;
 text-align: center;
} 

.DateRangePickerInput_arrow_1{
width: 10%;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
  border: 1px double #222B45;
}