@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.email-header{
    background-color: #4a6963;
background-image: url(../../assets/pngs/TopoLeft.png);
background-position: left top;
background-repeat: no-repeat;
position: relative !important;
width: 100%;
min-height: 80px;
}
.imageBackgroundHome {
    background-image: url(../../assets/backgrounds/HOME.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-color: rgba(70, 102, 96, 0.4);
    min-height:40vw;
   
  }
  .imageBackgroundVision {
    background-image: url(../../assets/pngs/Compass\ image.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment:scroll;
    background-size: cover;
    background-color: rgba(70, 102, 96, 0.4);
    min-height: 40vw;
  }
 .email-main-title {
    font-size: 55px;
    color: #ec7228;
    text-shadow: 5px 5px 15px black;
    font-family: "Hoefler";
    font-weight: bolder;
 
  
  }
  .application-title{
    font-weight: bold;
    font-size: 34px;
    letter-spacing: 0;
    line-height: 40px;
  }
.application-messagge-app{
  font-size: 13px;
  font-family: "SF Pro Text"
}
.application-messagge{
  font-size: 13px;
  font-family: "Roboto";

}
.application-vision {
  width: 40vw;
  color: #FFFFFF;
  font-family: "Roboto";
  font-size: 2vw;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;


}
.guidex{
  background-color: #ec7228 !important;
  color: white !important;
  height: 40px !important;
  width: 129px !important;
  font-weight: bold !important;
  font-family: "Roboto";
  font-size: 14px;
}
.email-unsubcribe{
  color: #46586B;
  text-decoration: underline;
}

.review-label{
 
  color: #8992A3;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: bolder;


}
.review-label-2{
font-weight:normal;
  color: #8F9BB3;
  font-family: "Roboto";
  font-size: 12px;
  letter-spacing: 0;
}
.guidex-input{
  background-color: rgb(247, 249, 252) 
}
.vision{
  height: 50px !important;
  width: 189px !important;
border-color: #ec7228 !important;
}
.vision-padding-bottom{
margin-bottom: 12%;
}
@media  (max-width: 576px) {
  .email-main-title {
    font-size: 35px;
    color: #ec7228;
    text-shadow: 5px 5px 15px black;
    font-family: "Hoefler";
    font-weight: bolder;
 
  
  }
  .application-vision {
    padding-top: 13%;
    width: 100%;
    color: #FFFFFF;
    font-family: "Roboto";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
  
  
  }
  .vision{
    height: 35px !important;
    width: 109px !important;
  border-color: #ec7228 !important;
font-size: 12px !important;
 
  }
  .application-title{
    font-weight: bold;
    font-size:24px;
    letter-spacing: 0;
    line-height: 40px;
  }
}
