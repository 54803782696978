@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.guide-registration-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif !important;
  height: 338px;
}

.guide-registration-title-h1 {
  font-size: 100px !important;
  font-weight: bold;
  color: white;
}

@media screen and (max-width: 600px) {
  .guide-registration-title-h1 {
    font-size: 46px !important;
    font-weight: bold;
    color: white;
  }
}

@media(min-width: 768px) AND (max-width: 1024px){
  .guide-registration-title-h1 {
    font-size: 70px !important;
  }
}

#imageBackgroundGuideRegistration {
  background-image: url(../../assets/pngs/guideRegistration.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: calc(50vh - 271px - 76px);
}

.btn-guide-registration {
  height: 58px;
  width: 118px;
  background-color: #ec7228 !important;
  color: white !important;
  font-size: 18px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
}

.guide-registration-form {
  padding-left: 9% !important;
  padding-right: 9% !important;
}

.clickable {
  cursor: pointer;
}

/* The container */
.checkbox-container {
  color: black;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #e1e4e8;
  border: 1px solid #c5cee0;
  border-radius: 3px;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #ec7228;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 8px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
