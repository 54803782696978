.footer-guidex {
  position: relative important;
  right: 0;
  bottom: 0;
  left: 0;
  color: white;
  min-height: 271px;
  background-image: url(../assets/icons/Topo.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #4a6963;
}

@media(max-width: 576px){
  .footer-guidex{
    padding-top: 15px;;
  }
  .footer-logo{
    margin-bottom: 20px;
  }
}

.ring-container {
  position: relative;
}

.footer-btn {
  font-size: 13px !important;
  font-family: "Roboto";
}

.circle {
  width: 8px;
  height: 8px;
  background-color: var(--guidexPrimary);
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 0px;
}

@media (max-width: 750px){
  .circle{
    top: 8px;
    left: 1px;
  }
}

.ringring {
  border: 1px solid var(--guidexPrimary);
  border-radius: 30px;
  height: 12px;
  width: 12px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  position: absolute;
  top: 5.75px;
  left: -1.25px;
}
@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
