@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

:root {
  --guidexPrimary: #ec7228;
  --guidexSecondary: #222b45;
  --guidexPrimaryLight: rgba(236, 114, 40, 0.08) ;
  --font-family-sans-serif: "Roboto", sans-serif !important;
  --avatar-size: 2.5rem;
  --guidexWhite: white;
}

html {
  overflow-x: hidden;
}

body {
  color: #222b45 !important;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif !important;
}

/** BUTTONS THEME GUIDEX */
.btn-guidex {
  background-color: var(--guidexPrimary) !important;
  color: white !important;
  padding: 11px 26px !important;
  font-weight: bold !important;
  font-family: "Roboto";
}
.btn-secondary-guidex{
  border-color: var(--guidexPrimary) !important;
  color: var(--guidexPrimary) !important;
  font-size: 1rem !important;
  background-color: var(--guidexPrimaryLight) !important; 
  padding: 10px 26px !important;
  font-weight: bold !important;
  font-family: "Roboto"
}
.btn-secondary-cancel{
  width: 150px;
  height: 45px;
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  border-color: var(--guidexPrimary) !important;
  background-color: var(--guidexPrimaryLight) !important;
  color: var(--guidexPrimary) !important;
}

.expeditions-cards-content {
  padding: 30px 30px 10px 30px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.select-account {
  min-height: 40px;
  max-height: 40px;
  max-width: 84px;
  text-align: center !important;
  padding: 0px 15px !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.btn-guidex-outline {
  border-color: white !important;
  color: white !important;
}

.btn-guidex-link {
  color: white !important;
}

.btn-guidex-outline-primary {
  background-color: rgba(236, 114, 40, 0.08) !important;
  border-color: var(--guidexPrimary) !important;
  color: var(--guidexPrimary) !important;
}

.btn-guidex-sm {
  padding: 8.5px 22px !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.btn-guidex-xs {
  padding: 4px 10px !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 10px !important;
  font-weight: bold !important;
}
.btn-guidex-guides {
  padding: 4px 10px !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 10px !important;
  font-weight: bold !important;
  color: var(--guidexWhite);
  background-color: var(--guidexPrimary);
}
.btn-guidex-guides:hover{
  border: none;
  color: var(--guidexWhite);
}

.btn-guidex-lg {
  padding: 12px 22.5px !important;
  font-size: 16px !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: bold !important;
}

.btn-guidex-social-media {
  background-color: var(--guidexPrimary) !important;
  color: white !important;
  padding: 8px 12px !important;
}

/** NAV THEME */
.guidex-navbar-transparet {
  background-color: transparent;
  position: absolute !important;
  width: 100%;
}

.guidex-navbar {
  background-color: #4a6963;
  background-image: url(./assets/pngs/Topo_Left.png),
    url(./assets/pngs/Topo_Right.png);
  background-position: left top, right top;
  background-repeat: no-repeat, no-repeat;
  position: absolute !important;
  background-size: 300px;
  width: 100%;
}

@media (min-width: 300px) AND (max-width: 1023px) {
  .guidex-navbar {
    background-image: url(./assets/pngs/Topo_Left.png);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 90vw;
  }
}

.bg-transparent-nav {
  background-color: rgba(0, 0, 0, 0);
}

.bg-green-light {
  background-color: #f6f6f6;
}

.bg-grey {
  background-color: #e9eaeb !important;
}

/** FOOTER THEME */

.price-original {
  text-decoration: line-through;
  font-weight: lighter;
  color: #8f9bb3;
  font-family: "Roboto";
}
.secondary-gray{
  color: #8f9bb3;
}
/** BODY IMAGE BACKGROUND */
#imageBackgroundOpacity {
  background-position: center center;
  background-repeat: no-repeat;
  min-width: 100vw;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif !important;
  background-attachment: fixed;
  background-size: cover;
  background: linear-gradient(0deg, rgba(124, 159, 153, 0) 0%, #46666066 100%);
  min-height: calc(140vh - 271px - 76px);
}

@media (min-width: 768px) AND (max-width: 1024px) {
  /* #imageBackgroundHome{
    min-height: 65vh !important;
    max-height: 65vh !important;
  } */
}

#imageBackgroundHome {
  background-image: url(./assets/backgrounds/HOME.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: calc(110vh - 271px - 76px);
}

#imageBackgroundSignin {
  background-image: url(./assets/backgrounds/SIGNIN.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: calc(110vh - 271px - 76px);
}

#imageBackgroundSignup {
  background-image: url(./assets/backgrounds/SIGNUP.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: calc(110vh - 271px - 76px);
}

#imageBackgroundContacUs {
  background-image: url(./assets/backgrounds/CONTACTUS.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: calc(110vh - 271px - 76px);
}

#imageBackgroundGuideInfo {
  background-image: url(./assets/backgrounds/GUIDE_INFO.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: calc(85vh - 271px - 76px);
}

@media (max-width: 576px) {
  #imageBackgroundGuideInfo {
    min-height: 70vh;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

#imageBackgroundFishing {
  background-image: url(./assets/backgrounds/FISHING.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: calc(110vh - 271px - 76px);
}

#imageBackgroundTopo {
  background-image: url(./assets/icons/Topo.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: contain;
  background-color: #4a6963;
  min-height: calc(50vh - 271px - 76px);
}

#imageBackgroundTopoAlt {
  background-image: url(./assets/pngs/Topo.png);
  background-position: top center;
  background-repeat: repeat;
  background-attachment: local;
  background-size: contain;
  background-color: #4a6963;
  min-height: calc(50vh - 271px - 76px);
}

#imageBackgroundNav {
  background-image: url(./assets/icons/Topo.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  background-color: #4a6963;
}

/* * CARDS */
.guidex-card {
  border-radius: 5px;
  background-color: white;
  padding: 60px !important;
  margin: 142px 0px;
}

.noMargin {
  background-color: white;

  margin: 0px 0px;
}

/* * FONT SIZES */
.font-size-9 {
  font-size: 9px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 19px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-30 {
  font-size: 30px;
}

.font-weight-500 {
  font-weight: 500;
  line-height: 18px;
}

.font-family-Roboto {
  font-family: "Roboto", sans-serif !important;
}

.font-family-Montserrat {
  font-family: "Montserrat", sans-serif !important;
}

/* COLORS */
.primary-color {
  color: var(--guidexPrimary) !important;
}

.secondary-color {
  color: var(--guidexSecondary) !important;
}

.white-font {
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}

/* * FORMS */
label {
  color: #8992a3;
  font-size: 12px;
  font-weight: bold;
}

.form-control {
  background-color: #f7f9fc !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  height: auto !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: url(./assets/icons/check-mark.svg) !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #f4541d !important;
  background-image: url(./assets/icons/alert.svg) !important;
}

.invalid-feedback {
  background-repeat: no-repeat;
  background-position: left calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  color: #f4541d !important;
  padding-left: calc(1.5em + 0.75rem);
  background-image: url(./assets/icons/alert.svg) !important;
}

/** STATIC PAGES*/
.page-title {
  text-align: center;
  padding: 100px 0 100px 0;
}

.page-title h1 {
  font-size: 94px;
  color: white;
  padding: 21px 30px;
  font-weight: bold;
  text-shadow: 2px 2px black;
}

.page-quote {
  width: 95%;
  background-color: var(--guidexSecondary);
  margin: auto;
  color: white;
  padding-bottom: 50px;
}

.page-quote-title {
  font-size: 18px;
  font-family: "Roboto";
  padding: 50px 60px 10px 60px;
  width: 100%;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.page-quote-title span:after {
  background-color: white;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 322px;
}

.page-quote-content {
  font-size: 30px;
  font-family: "Roboto";
  font-weight: bolder;
  padding: 10px 60px;
}

/** GUIDE INFO */

.user {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 0px;
}

.type {
  color: #8f9bb3;
  margin-top: 0px;
}

.guide-logo {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.roboto {
  font-family: "Roboto";
}

.gray-background {
  background-color: #f6f6f6;
  background-size: cover;
}

.form-title {
  color: #8992a3;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: bolder;
}

.modal-header {
  border-bottom: 0px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.square {
  height: 30px;
  width: 30px;
  background-color: var(--guidexPrimary) !important;
  color: white;
  text-align: center;
}

.new-trip-information {
  margin-left: -3.2%;
}

INPUT[type="checkbox"]:focus {
  outline: 1px solid #c5cee0;
}

INPUT[type="checkbox"] {
  background-color: #8f9bb334;
  border-radius: 3px;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid #c5cee0;
  -moz-appearance: none;
  width: 17px;
  height: 17px;
  cursor: pointer;
  position: relative;
  top: 3px;
  margin-right: 6px;
}

INPUT[type="checkbox"]:checked {
  background-color: #ec7228;
  background: #ec7228 url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 4px 4px no-repeat;
  background-size: 8px;
}

.bg-green {
  background-color: #f6f6f6;
}

.search-bar {
  width: 98% !important;
  margin: 4px;
  background: url(./assets/icons/search.svg) no-repeat;
  background-position: 1.3%;
  background-color: rgba(70, 102, 102, 0.08) !important;
  padding-left: 6.6% !important;
  border: none;
}

.btn-guidex-light {
  background-color: rgba(236, 114, 40, 0.08) !important;
  border-color: var(--guidexPrimary) !important;
  color: var(--guidexPrimary) !important;
  padding: 11px 26px !important;
  font-weight: bold !important;
}

.background-guidex {
  background-color: rgba(70, 102, 102, 0.08) !important;
}

.form-label span {
  font-weight: lighter !important;
}

.owner-circle {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: center;
}

.circle-profile {
  background-color: #466660;
  border-radius: 50%;
  height: var(--avatar-size);
  text-align: center;
  width: var(--avatar-size);
}

.initials {
  /*font-size: calc(var(--avatar-size) * 0.4); /* 50% of parent */
  font-size: 15px;
  line-height: 1;
  position: relative;
  top: calc(var(--avatar-size) / 4);
  /* 25% of parent */
  color: white;
  text-transform: uppercase;
}

.role-name {
  text-transform: capitalize;
}

.lateral-menu {
  margin-bottom: 3rem !important;
}

.lateral-menu-item {
  padding: 20px 15px !important;
}

.lateral-menu .lateral-menu-header {
  background-color: white !important;
}

.lateral-menu .lateral-menu-item {
  font-size: 15px;
  cursor: pointer;
  background-color: white !important;
}

.lateral-menu .lateral-menu-item:hover {
  background-color: rgba(236, 114, 40, 0.15) !important;
  border-left: 3px solid rgba(236, 114, 40, 1) !important;
}

.lateral-menu .lateral-menu-item .selected {
  display: none;
}

.lateral-menu .lateral-menu-item-selected {
  cursor: pointer;
  background-color: rgba(236, 114, 40, 0.15) !important;
  border-left: 3px solid rgba(236, 114, 40, 1) !important;
  color: var(--guidexPrimary);
  font-size: 15px;
  padding: 20px 15px;
}

.lateral-menu .lateral-menu-item-selected .no-selected {
  display: none;
}

.clickable {
  cursor: pointer;
}

.invisible-trigger {
  visibility: hidden;
  height: 0;
}

.max-width-content {
  max-width: 1100px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.edit-img-icon {
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.btn-settings-edit-icon {
  position: relative;
  top: -30%;
  left: 80%;
  width: 25px !important;
  height: 25px !important;
  background-color: var(--guidexPrimary) !important;
  color: white !important;
}

.edit-img-profile {
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.btn-settings-edit-profile {
  position: absolute;
  top: 5px;
  right: 20px;
  width: 35px !important;
  height: 35px !important;
  background-color: var(--guidexPrimary) !important;
  color: white !important;
}
.btn-pen-icon-edit{
  position: absolute;
  top: 5px;
  right: 20px;
}
.btn-settings-edit-icon-pen{
  position: relative;
  top: -40%;
  left: 70%;
}

.wrapper-icon {
  width: 80px !important;
  height: 80px !important;
}

.addition-info-section {
  background-color: #e9eaeb;
  padding: 30px;
}

.list-group-item-section {
  background-color: transparent;
  background-size: cover;
  padding-left: 9%;
  border: 0;
  color: #222b45;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
}

.list-group-item-section a {
  color: #222b45 !important;
  padding: 20px 0;
}

.list-group-item-section a:hover {
  color: #ec7228 !important;
  border-bottom: 5px solid #ec7228;
  padding-bottom: 15px;
}

.list-group-item-section a[aria-selected="true"] {
  color: black !important;
  border-bottom: 5px solid var(--guidexPrimary);
  padding-bottom: 15px;
}

img.img-gallery-slot {
  width: 100%;
  object-fit: fill;
}
.button-erase-container{
  position:relative
}
.button-erase-image{
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: #ec7228;
  border-radius: 3px ;
  color: #e9eaeb;
}

.modal-principal .modal-dialog {
  height: 90%;
  display: block;
  margin: auto;
}

.modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

.btn-procced {
  color: var(--guidexPrimary) !important;
  font-weight: bold !important;
  font-size: 13px !important;
}

.button-procced {
  margin-top: 60% !important;
}

.warning-modal {
  background-color: #ec7228 !important;
  opacity: 0.8 !important;
}

.btn-submit-mobile {
  background-color: var(--guidexPrimary) !important;
  color: white !important;
  display: block !important;
  width: 100%;
  font-weight: bold !important;
  font-family: "Roboto";
}

.btn-login-responsive{
  width: 126px;
  height: 45px;
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: #ec7228 !important;
  color: white !important;
}


@media (max-width: 576px) {
  .guidex-card {
    padding-top: 10% !important;
    padding-left: 7% !important;
    padding-right: 6% !important;
    padding-bottom: 6% !important;
  }

  .guidex-card .btn-guidex {
    display: block !important;
    width: 100%;
  }

  .link-btn {
    display: block !important;
    width: 100%;
  }
}
.input-responsive{
width: 90%  !important;
}
