.table-species-thead{
width: 15%;
}
.table-species tr{
    border-bottom: 1px solid #8F9BB3;
    align-items: center;
    
}
.table-species td{
    vertical-align:middle !important;
    padding-bottom: 2% !important;
    padding-top: 2% !important;
}

.no-border{
    border: 0 !important;
}
.btn-species-light{
    background-color: rgba(236, 114, 40, 0.08) !important;
    border-color: var(--guidexPrimary) !important;
    color: var(--guidexPrimary) !important;
   
    font-weight: bold !important;
    height: 36px !important;
    width: 74px;
    font-size: 14px !important;
   
}
.btn-species{
    background-color: var(--guidexPrimary) !important;
    color: white !important;
    font-weight: bold !important;
    font-family: "Roboto";
    height: 36px !important;
    width: 84px;
    font-size: 14px !important;
 
}