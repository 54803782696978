.advertising-circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
  .first-col{
margin-left: 3rem !important;
margin-right: .2rem !important;
flex: 0 0 3.333333% !important;
max-width: 3.333333% !important;
  }

.second-col{
    flex: 0 0 3.333333% !important;
max-width: 3.333333% !important;
    
}
.third-col{
        margin-left: 2.5rem !important;
        
          }

        .progress-bar {
            background-color:  #E9EAEB !important;
              border-radius: 10px !important; 
          }

        .progress {
            display: flex;
            height: 8px !important;
            overflow: hidden;
            line-height: 0;
            font-size: .75rem;
            background-color: white !important;
            border-radius: 10px !important;
        }

        .progress-bar-active {
            background-color:  #ec7228 !important;
              border-radius: 10px !important; 
          }