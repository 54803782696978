@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.customer {
  padding-top: 76px;
}

.noBackground {
  background-color: white;
}

.portal-menu {
  min-height: 73.25vh;
  padding-right: 0 !important;
}

.portal-section {
  min-height: 73.25vh;
  background-color: #f1f3f3;
}
.portal-section-responsive {
  min-height: 73.25vh;
  background-color: #f1f3f3;
  width: 100%;
}

.portal-section-title {
  height: 48px;
  width: 300px;
  color: #466660;
  font-family: "Montserrat";
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
}
.portal-section-title-responsive {
 
  color: #466660;
  font-family: "Montserrat";
  font-size: 46px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
}

.modal-dialog {
  margin-top: 5% !important;
}
.modal-lg {
  max-width: 650px !important;
}
.clickable:hover {
  cursor: pointer;
}
.approve-guides-title {
}

/* .list-group-item:hover {
  background-color: rgba(0,0,0,0.03);
} */

.approve-guides-container {
  width: 70% !important;
  background-color: white;
  padding-left: 2% !important;
  padding-right: 2% !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  box-shadow: 2px 3px 10px 5px rgba(0, 0, 0, 0.24);
}
.approve-guides-container-responsive {
  width: 100% !important;
  background-color: white;
  padding-left: 5% !important;
  padding-right: 5% !important;
  padding-top: 5% !important;
  padding-bottom: 10% !important;

}
.approve-guides-title {
  width: 70% !important;
  margin-left: auto;
  margin-right: auto;
}
.approve-guides-title-responsive {
  width: 100% !important;
 
}
.btn-details {
  height: 35px;
  width: 107px;
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: var(--guidexPrimary) !important;
  color: white !important;
}

.customer {
  padding-top: 76px;
}

.staff-approve-title {
  padding-left: 15%;
  color: #466660;
  font-family: "Montserrat";
  font-size: 36px;
  font-weight: bold;
}
.approve-guides-table td th {
  width: 11% !important;
}

.approve-guides-table tr {
  border-bottom: 1px solid #8f9bb3;
}
.approve-guides-table thead tr {
  border-bottom: 0;
}

.approve-guides-table td {
  padding-top: 1.8% !important;
  padding-bottom: 1.8% !important;
}

.login-search {
  width: 60% !important;
  height: 67% !important;
  padding: 15px;
  background: url(../../assets/icons/search.svg) no-repeat;
  background-position: 95.3%;
  background-color: rgba(48, 49, 51, 0.1) !important;
  padding-left: 45px;
  border: none;
}

.btn-reject {
  border-color: #ec7228 !important;
  color: #ec7228 !important;
  height: 50px;
  width: 101px;
  font-weight: bold !important;
  font-size: 18px !important;
  background-color: rgba(236, 114, 40, 0.1) !important;
}
.btn-approved {
  height: 50px;
  width: 117px;
  font-size: 18px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: var(--guidexPrimary) !important;
  color: white !important;
}

.license-number-box {
  padding: 15px;

  background-color: rgba(236, 114, 40, 0.1) !important;
}

.staff-back {
  margin-top: 30px;
  margin-bottom: 1%;
  margin-left: 14% !important;
  font-weight: bold;
}

.back-text {
  font-size: 14px;
  font-weight: bold;
}

.rejection-modal {
  padding-left: 8% !important;
  padding-right: 8% !important;
}
.btn-cancel {
  border-color: #ec7228 !important;
  color: #ec7228 !important;
  width: 111px;
  padding: 11px 26px !important;
  font-weight: bold !important;
  font-size: 18px !important;
  background-color: rgba(236, 114, 40, 0.1) !important;
}

.review-forms {
  /* width: 60% !important;
  height: 67% !important; */
  background-color: #f7f9fc !important;
}
.review-form-textarea {
  /* width: 80.2% !important;
  height: 67% !important; */
  background-color: #f7f9fc !important;
}

.review-max-characters {
  color: #8f9bb3;
  font-family: "Roboto";
  font-size: 12px;
}

.btn-send {
  width: 90px;
  font-size: 18px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: var(--guidexPrimary) !important;
  color: white !important;
}

.change-report-table td {
  width: 1% !important;
}
.change-report-table tr {
  border-bottom: 1px solid #8f9bb3;
}
.change-report-table thead tr {
  border-bottom: 0;
}

.change-report-table td {
  padding-top: 1.8% !important;
  padding-bottom: 1.8% !important;
}

.reviews-tab {
  padding-left: 16% !important;
}
.reviews-tab p:hover {
  color: var(--guidexPrimary) !important;
  border-bottom: 5px solid var(--guidexPrimary) !important;
  padding-bottom: 10px;

  padding-right: 4%;
}

.head-20 {
  width: 13% !important;
}
.head-40 {
  width: 65% !important;
}

.reviews-collapse {
  position: absolute;
  margin: 1px 0 0 0;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 2px #aaaaaa;
  right: 2%;
}
.collapse-list-item {
  padding-right: 140px !important;
  padding-top: 7% !important;
  padding-bottom: 7% !important;
}
.collapse-list-item:hover {
  color: #ec7228 !important;
  border-left: 5px solid var(--guidexPrimary) !important;
  font-weight: bold !important;

  background-color: rgba(236, 114, 40, 0.1) !important;
}

.reviews-guide-container {
  background-color: white;
  padding-left: 2% !important;
  padding-right: 2% !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  box-shadow: 2px 3px 10px 5px rgba(0, 0, 0, 0.24);
}
.reviews-tab-guide p:hover {
  color: var(--guidexPrimary) !important;
  border-bottom: 5px solid var(--guidexPrimary) !important;
  padding-bottom: 10px;
  cursor: pointer;
  padding-right: 6%;
  padding-left: 1%;
}

.reviews-tab-guide .active {
  color: var(--guidexPrimary) !important;
  border-bottom: 5px solid var(--guidexPrimary) !important;
  padding-bottom: 10px;
  cursor: pointer;
  padding-right: 6%;
  padding-left: 1%;
}

.reviews-tab-guide p {
  /* color: var(--guidexPrimary) !important;
  border-bottom: 5px solid var(--guidexPrimary) !important; */
  padding-bottom: 10px;

  padding-right: 6%;
  padding-left: 1%;
}

.rating-cell {
  min-width: 130px;
}

#dropdowmTable button {
  color: #ec7228;
  background-color: white;
  border-color: white;
}

#dropdowmTable .dropdown-table {
  padding: 20px 100px 20px 14px;
}

#dropdowmTable .dropdown-table:hover {
  background-color: #ec722814;
  border-left: 4px solid #ec7228;
  padding: 20px 100px 20px 10px;
}

#dropdowmTable .dropdown-menu {
  padding: 0 !important;
}
.reviews-table {
  /* overflow: hidden; */
}
.table-responsive {
  overflow-x: visible !important;
}
