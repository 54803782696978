.guidex-card2 {
    background-color: white;
    padding: 60px !important;
    margin: 142px 255px !important;


  }

  .contact-guide-title{
 
    color: #466660;
    font-family: "Roboto";
    font-size: 22px;
    font-weight: bold;
    
  }

  .guidex-card2 {
    background-color: white;
    margin: 20% 3% !important;
  }
  @media  (max-width: 576px) {
    .guidex-card2 {
      background-color: white;
      padding: 5.2% !important;
      margin: 30% 3% !important;
  
  
    }
  }