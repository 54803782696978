.floating-card {

  background-image: url("../assets/pngs/floatingCardBk.png");
  padding: 80px !important;
  position: relative;
  top: -11vh;
  color: white;
}

.floating-card-2 {
  max-width: 75vw;
  background-color: white;
  padding: 47px 26px !important;
  position: relative;
  top: -10vh;
  color: #ec7228;
}

.floating-text {
  color: white;
  /* font-size: 18px; */
  font-weight: bold;
}

.floating-text-2 {
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
}
/* 
// Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .floating-card {
    padding: 10% !important;
    font-size: 10px !important;
  }

  .floating-text {
    font-size: 20px;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .floating-text-2 {
    font-size: 30px;
  }

  .floating-card-2 {
    width: 100%;
    padding: 70px !important;
  }


}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .floating-card {
    max-width: 80%;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}