@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.hunting-search-bar-wrapper {

  padding-top: 100px !important;

  width: 100% !important;
}

#apply-btn-plp {
  margin-left: 20px;
  padding: 12px 22px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 3px;
  background-color: #EC7228;
}

#cancel-btn-plp {
  padding: 12px 22px;
  color: #EC7228;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #EC7228;
  background-color: #EC722814;
}

.huntingPLP-padding {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
}

.menuActive {
  left: 36px !important;
  z-index: 200;
  border-radius: 5px;
  padding: 15px;
  position: absolute;
  bottom: -48vh;
  width: 90%;
  background-color: white;
  box-shadow: 10px 16px 15px 10px rgb(48 49 51 / 50%);

}



.btn-hunting {
  height: 32px;
  border-radius: 20px !important;
  background-color: #ec7228 !important;
  line-height: 16px;
  color: white !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  padding: 0 18px;
  cursor: text !important;
}

.clear-filter {
  margin: 0 0 0 10px;
  cursor: pointer;
}

.hunting-clear {
  padding: 8px 0 0 0;
  color: #8F9BB3;
  font-size: 12px !important;
  line-height: 16px;
  cursor: pointer;
}

.hunting-bar-species {
  width: 100%;
  padding: 15px;
  background: url(../../assets/icons/navigation.svg) no-repeat;
  background-position: 99%;
  background-color: rgba(48, 49, 51, 0.1);

}

.hunting-bar-time {
  width: 100%;
  padding: 15px;
  background: url(../../assets/icons/navigation.svg) no-repeat;
  background-position: 99%;
  background-color: rgba(48, 49, 51, 0.1);

}

.hunting-bar-range {
  width: 100%;
  padding: 15px;
  background: url(../../assets/icons/navigation.svg) no-repeat;
  background-position: 99%;
  background-color: rgba(48, 49, 51, 0.1);

}

.hunting-bar-location {
  width: 100%;
  padding: 15px;
  background: url(../../assets/icons/navigation.svg) no-repeat;
  background-position: 99%;
  background-color: rgba(48, 49, 51, 0.1);

}

.huntingPLP-select {
  font-weight: bold !important;
}

.huntingPLP-input {
  width: 46% !important;
  font-weight: bold !important;
}


.huntingPLP-search {
  text-align: initial;
  width: 100%;
  padding: 15px;
  background: url(../../assets/icons/search.svg) no-repeat;
  background-position: 5%, 95%;
  background-color: rgba(48, 49, 51, 0.1);
  padding-left: 45px;
  border: none;
}

.collapse-form-container {
  position: absolute;
  top: 60px;
  z-index: 10;
}

.collapse-form {
  margin: 1px 0 0 0;
  background-color: #ffffff;
  box-shadow: 1px 1px 2px 1px #aaaaaa;
  padding: 10px 0;
  border-radius: 3px;
}



.arrow_box:after,
.arrow_box:before {
  margin-top: 10px;
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 8px;
  left: 10%;
  margin-left: -8px;
}

.arrow_box:before {
  border-color: rgba(113, 158, 206, 0);
  border-bottom-color: #719ECE;
  border-width: 8px;
  left: 10%;
  margin-left: -8px;
}

.collapse-form-container-center {
  right: -40px;
}

.arrow_box-center:after,
.arrow_box-center:before {
  margin-top: 10px;
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box-center:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 8px;
  left: 50%;
  margin-left: -8px;
}

.arrow_box-center:before {
  border-color: rgba(113, 158, 206, 0);
  border-bottom-color: #719ECE;
  border-width: 8px;
  left: 50%;
  margin-left: -8px;
}

.collapse-form-container-right {
  right: 10px;
}

.arrow_box-right:after,
.arrow_box-right:before {
  margin-top: 10px;
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box-right:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 8px;
  left: 95%;
  margin-left: -8px;
}

.arrow_box-right:before {
  border-color: rgba(113, 158, 206, 0);
  border-bottom-color: #719ECE;
  border-width: 8px;
  left: 95%;
  margin-left: -8px;
}

.pop-over {
  color: white !important;
  background-color: black !important;
}

.plp-slider {
  -webkit-appearance: none;
  height: 8px;
  width: 100%;
  border-radius: 6px !important;
  background: linear-gradient(270deg, rgba(243, 6, 6, 1) 0%, rgba(56, 170, 2, 1) 100%) !important;
}

.plp-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #C5CEE0;
  background-color: #F1F3F3
}

.more-checkboxes label {

  color: #222B45 !important;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: bold !important;

}


.hunting-search-bar input[type="text"] {
  border: none;
  width: 100% !important;
}

.hunting-search-bar input[type="number"] {
  border: none;
  width: 100% !important;
}

.hunting-search-bar select {
  border: none;
  width: 100% !important;
}

.hunting-search-bar input:focus {
  outline: none !important;
  border: 1px solid #ec7228;
  box-shadow: 0 0 5px #ec7228;
}

.hunting-search-bar select:focus {
  outline: none !important;
  border: 1px solid #ec7228;
  box-shadow: 0 0 5px #ec7228;
}

.hunting-search-bar input[type="text"]::placeholder {
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
}

.hunting-search-bar input[type="number"]::placeholder {
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
}

.hunting-search-bar input[type="text"].searchbar-another-species-hunting {
  background: url(../../assets/icons/location1.svg) no-repeat;
  background-position: 95%;
}

.hunting-search-bar input[type="text"].searchbar-another-species-fishing {
  background: url(../../assets/icons/location2.svg) no-repeat;
  background-position: 95%;
}

.hunting-search-bar input[type="number"].searchbar-party-size-fishing {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
  background: url(../../assets/icons/people.svg) no-repeat;
  background-position: 95%;
}

.hunting-search-bar input[type="text"].searchbar-time-frame-hunting {
  background: url(../../assets/icons/calendar.svg) no-repeat;
  background-position: 95%;
}

.hunting-search-bar input[type="text"].searchbar-time-frame-fishing {
  background: url(../../assets/icons/clock.svg) no-repeat;
  background-position: 95%;
}

.hunting-search-bar input[type="text"].searchbar-price-range {
  background: url(../../assets/icons/money\ icon.svg) no-repeat;
  background-position: 95%;
}

.hunting-search-bar input[type="text"].searchbar-location {
  background: url(../../assets/icons/map.svg) no-repeat;
  background-color: #ec7228;
  background-position: 95%;
}

.collapse-form-time-frame {
  width: 358px;
}

.collapse-form-party-size {
  width: 358px;
}

.collapse-form-price {
  width: 357px;
}

.collapse-form-location {
  width: 328px;
}

.collapse-form-more {
  width: 331px;
}

.collapse-form-price ul.price-suggestion {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.collapse-form-price ul.price-suggestion li {
  padding: 10px 0 0 0;
  cursor: pointer;
}

.collapse-form-price ul.price-suggestion li::before {
  color: transparent !important;
}

.price-divider {
  margin: 10px 0 0 0;
}



.more-btn {
  text-align: center;
  padding: 12px 10px !important;
}

.hunting-search-bar {
  background-color: rgba(255, 255, 255, 0.8);
  width: 96% !important;
}



@media(max-width: 991px) {
  .hunting-search-bar {
    background-color: rgba(255, 255, 255, 0.8);
    width: 100% !important;
  }

  .huntingPLP-padding {
    padding-left: 0%;
    padding-right: 0%;
  }
}

@media(max-width: 575px) {
  .dropdown-toggle-custom {
    width: 95vw;
    background-color: #FFFFFF !important;
    color: #466660 !important;
    border-color: #FFFFFF !important;
    font-size: 18px !important;

  }



  .hunting-search-bar-wrapper {
    margin-bottom: 10px !important;
  }

  .search-expeditions-title {
    margin-top: 170px !important;
  }

  .dropdown-menu {
    width: 95vw;
    left: -16px !important;
  }

}

@media(min-width: 576px) AND (max-width: 1022px) {
  .dropdown-toggle-custom {
    width: 100vw;
    background-color: #FFFFFF !important;
    color: #466660 !important;
    border-color: #FFFFFF !important;
    font-size: 18px !important;

  }

  .huntingPLP-padding {
    padding-left: 0px;
    padding-right: 0px;
  }



  .hunting-search-bar-wrapper {
    margin-bottom: 10px !important;
    padding-top: 100px !important;
  }



  .dropdown-menu {
    width: 95vw;
    left: -16px !important;
  }

  .matches {
    padding-top: 90px !important;
    padding-bottom: 10px !important;
    padding-left: 35px !important;
  }
}

@media(min-width: 1023px) AND (max-width: 1200px) {


  .hunting-search-bar-wrapper {
    margin-bottom: 10px !important;
  }

  .matches {
    padding-left: 40px !important;
  }
}