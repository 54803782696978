.advertising-area-container {
  background-color: white;
  width: 99% !important;
   padding-left: 2% !important;
   padding-right: 2% !important;
   padding-top: 2% !important;
   padding-bottom: 2% !important;
   box-shadow: 2px 3px 10px 5px rgba(0, 0, 0, 0.1);
   
 }
 .search-icon{  
   background:url(../../../../assets/icons/search.svg) no-repeat;
   background-position: 7.3%;
   background-color: rgba(70, 102, 102, 0.08) !important;
   padding-left: 15.6% !important;
 }

 .active-areas tr {
border-bottom: 1px #C5CEE0 solid !important;
padding-top: 1% !important;
padding-bottom: 1% !important;

 }
.active-areas .tr-head{
   border-bottom: 0 !important;
   padding-bottom: 0 !important;
 }
