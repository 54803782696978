.ranking-image{
  border: 1px;
  border-radius: 5px;
    width: 8vh;
height: 8vh !important;
}

.ranking-row{
    width: 10% !important;
}
.ranking-message{
    background-color: rgba(236, 114, 40, 0.08) !important;
    width: 97% !important;
    padding-top: 10px !important;
    padding-bottom: 10px;
    border: 1px solid #EC7228;
  border-radius: 4px
}
.ranking-table tr {
    border-bottom: 1px #C5CEE0 solid !important;
   
    
      }
.ranking-table .ranking-table-message  {
    
 border-bottom: none !important;
padding-top: 1% !important;
padding-bottom: 1% !important;
 
      }
      .text-td{
        width: 120px !important;
        text-align: right;
      }
     .ranking-table  .tr-head{
        border-bottom: 0 !important;
        padding-bottom: 0 !important;
      }
     

      .dot-advertising-live {
        height: 10px;
        width: 10px;
        background-color: green;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }

      .dot-advertising-inactive {
        height: 10px;
        width: 10px;
        background-color: orangered;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }

      .dot-advertising-pending {
        height: 10px;
        width: 10px;
        background-color: rgb(255, 238, 0);
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }