
.squares{

    background-color: #E9EAEB;

}
.new-trip-text-photo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.photo-text-title{
  
   margin-left: 20%;
  
}
.btn-new-trip-add-photo{
    color: white !important; 
    background-color: #EC7228 !important;
    font-size: 25px !important; 
    height:30px;
    width:30px;
    display: flex !important;
    align-items: center  !important;
    justify-content: center  !important;
    position: absolute;
        top: 6%;
        left: 94%;
        transform: translate(-50%, -50%);
}
.btn-new-trip-remove-photo{
  color: white !important; 
  background-color: #EC7228 !important;
  display: flex !important;
  align-items: center  !important;
  justify-content: center  !important;
  position: absolute;
  top: 6%;
  left: 5%;
  font-size: 20px;
  transform: translate(-50%, -50%);
  height:30px;
  width:30px;
  overflow: hidden;
  object-position: center;
  border-radius: 4px;
}

.trips-container{
    padding-left: 3% !important;
    padding-right: 10% !important;
    padding-top: 2% !important;
    padding-bottom:  2% !important;
    box-shadow:   2px 3px 10px 5px rgba(0,0,0,0.24);
}

.new-trip-menu
{
background-color: #E9EAEB;
  margin-left: -3.2% !important;
  padding-top: 2%;
}
.new-trip-input
{
width: 90% !important;
}

.new-trip-list> .list-group-item{
    
    background-color:  #E9EAEB !important;
    background-size: cover;
    padding-left: 9% !important;
    border: 0 !important;
    color: #222B45 !important;
    font-family: "Roboto";
    font-weight: bold;

}
.new-trip-list> .list-group-item a{
    color: #222B45 !important;
}


.new-trip-list> .list-group-item a:hover {
    color: var(--guidexPrimary) !important;
    border-bottom: 5px solid var(--guidexPrimary);
    padding-bottom: 10px;
    padding-left: 7%;
    padding-right: 20%;
    display: inline-block;
    width: auto;

  }
  .btn-save-and-next{
    background-color: #EC7228 !important;
    color: white !important;
    height: 40px;
    width: 135px;
    font-family: "roboto";
    
  }
  .new-trip-buttons{
      margin-right: 9% !important;
  }
  .tags-guidex-no-select{
    padding-left: 1.5% !important;
    padding-right: 1.5% !important;
    border-color: #ec7228 !important;
    color: #ec7228 !important;
   
  font-weight: bold !important;
  font-size: 14px !important;
  background-color:rgba(236, 114, 40 ,0.1) !important;
  }
  .tags-guidex-select{
    background-color: #EC7228 !important;
    padding-left: 1.5% !important;
    padding-right: 1.5% !important;

    font-size: 14px !important;
    color: white;
  }

  
.CalendarDay__selected_span {
  background: #ec7228; 
  color: white; 
  border: 1px solid; 
}

.CalendarDay__selected {
  background:#ec7228;
  color: white;
}

.CalendarDay__selected:hover {
  background: #ec7228;
  color: white;
}


.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #ec7228;
  color: white;
}
.CalendarDay__selected_span:hover{
  background: #222B45;
}

.DateInput_input__focused{
  border-bottom: 2px solid #222B45;
}
.DateRangePicker{
  width: 100%;
  
}
.DateRangePickerInput{
  width: 100%;
  background: #f7f9fc !important;

}
.DateInput_1{
  width: 45% !important;
  text-align:  right;
  background: #f7f9fc !important;
} 
.DateInput_input{
  background: #f7f9fc !important;
 text-align: center;
} 

.DateRangePickerInput_arrow_1{
width: 10%;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
  border: 1px double #222B45;
}