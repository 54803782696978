.list-group-item {
  background-color: #e9eaeb;
  background-size: cover;
  padding-left: 9%;
  border: 0;
  color: #222b45;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
}

.list-group-item a {
  color: #222b45 !important;
  padding: 20px 0;
}

.list-group-item a:hover {
  color: #ec7228 !important;
  border-bottom: 5px solid #ec7228;
  padding-bottom: 15px;
}

.list-group-item a[aria-selected="true"] {
  color: black !important;
  border-bottom: 5px solid var(--guidexPrimary);
  padding-bottom: 15px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #EC722814 !important;
}