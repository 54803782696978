@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.about-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif !important;
}

.about-title-h1 {
  font-size: 60px !important;
  font-weight: bold;
  color: white;
}
#imageBackgroundAbout {
  background-image: url(../../assets/pngs/Guides.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: calc(140vh - 271px - 76px);
}

/* 
// Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  #imageBackgroundAbout{
    min-height: 75vh;
    max-height: 75vh;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .about-title-h1 {
    font-size: 94px !important;
  }
}
@media(min-width: 768px) AND (max-width: 1024px){
  #imageBackgroundAbout{
    min-height: 60vh;
    max-height: 60vh;
  }
  .max-widht-card {
    max-width: 90vw !important;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
