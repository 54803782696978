.table-confirmation .tr-head-confirmation{
    border-bottom: 1px #C5CEE0 solid !important;
  }
  .confirmation-title{
      width: 80%;
      font-weight: bold;
      border-bottom: 1px #C5CEE0 solid !important;
      padding-left: 3%;
      padding-bottom:1.5% ;
  }
  .border-complete{
      width: 100%;
  }
  .confirmation-order{
      width: 50%;
  }
  .confirmation-icons{
      color: #8F9BB3;
  }