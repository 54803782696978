
.outfitter-card {
  width: 100%;
  max-height: 224px;
  font-family: 'Roboto', sans-serif !important;
}
.outfitter-card img {
  max-height: 224px;
  border: none;
}
/* .match-image img {
  height: auto;
  width: 100%;
} */
.btn-match {
  width: 116px;
  height: 40px;
  font-size: 14px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: #ec7228 !important;
  color: white !important;
}
.limited-cards-description{
  max-height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 13px;
}

a {
  text-decoration: none !important;
}
.img-matches {
  width: 100%;
  max-height: 224px;
  object-fit: cover;
  height: 100%;
}
.outfitter-match {
  padding-left: 115px;
  padding-right: 100px;
}

@media(max-width: 576px){
  .outfitter-match{
    padding-left: 15px;
    padding-right: 15px;
  }
  .matches-info{
    font-size: 13px !important;
  }
  .btn-learn-more{
    margin-top: 1rem;
  }
}

@media(min-width: 768px) AND (max-width: 1024px){
  .outfitter-match{
    padding-left: 39px;
    padding-right: 39px;
  }
  .btn-learn-more{
    margin-top: 0px;
    padding-left: 2.5rem !important;
  }
}

@media(min-width: 577px) AND (max-width: 768px){
  .btn-learn-more{
    margin-top: 0px;
    padding-left: 1.8rem !important;
  }
}


.matches-info{
  font-size: 13px;
}

.card-match {
  width: 100%;
}

.btn-match-results {
  border-color: #ec7228 !important;
  color: #ec7228 !important;
  height: 63px;
  width: 254px;
  font-weight: bold !important;
  font-size: 19px !important;
  background-color: rgba(236, 114, 40, 0.1) !important;
}
.outfitter-main-content {
  padding: 20px 30px 20px 35px !important;
}

.image-size {
  overflow: hidden;
  object-fit: cover;
  height: 100% !important;
}
