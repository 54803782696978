.guide-main-points {
  background-image: url(../../assets/backgrounds/Topography.jpg);
  background-position: center center;
  background-size: cover;
}
.margin-top-50 {
  margin-top: 50px;
}
.guide-card {
  width: 350px;
  height: 450px;
  box-shadow: 0 4px 8px rgba(48, 49, 51, 0.1);
  background-color: white;
  margin-top: 280px;
}
.guide-card h2 {
  font-size: 22px;
  padding: 20px 10px 10px 10px;
}
.guide-card p {
  font-size: 15px;
  padding: 10px 10px 10px 10px;
}

.btn-guide-register {
  background-color: var(--guidexPrimary) !important;
  color: white !important;
  height: 60px;
  width: 190px;
  font-size: 18px !important;
}
.become-guide-title {
  margin-top: 120px;
  margin-bottom: 20px;
  font-size: 94px !important;
  font-weight: bold;
  color: white;
}

.second-container {
  margin: 0;
  padding: 0;
}

.transparen-background {
  background-color: rgba(48, 49, 51, 0);
}
.shadow-card {
  box-shadow: 0 16px 15px 0 rgba(48,49,51,0.1);
}
/* Mobile */
@media(max-width: 500px){
  .become-guide-title{
    font-size: 60px !important;
  }
}