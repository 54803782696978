#TopographyBackground {
  background-image: url(../../../assets/backgrounds/Topography.jpg);
  background-position: center center;
  background-size: cover;
  max-height: 500px;
  overflow-y: scroll;
}
.message {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  width: 70%;
}

.messages-list {
  box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.btn-messages-send {
  background-color: var(--guidexPrimary) !important;
  color: white !important;
  font-size: 20px !important;
  height: 48px;
  width: 118px;
}
.messages-title {
  height: 10% !important;
  padding: 8px;
  padding-left: 14px !important;
}
.customer-list{
  height: 60vh!important;
  overflow-y: scroll;
}
.messages-body {
  height: 60vh !important;
}

.messages-footer {
  height: 15% !important;
}
.message-user {
  background-color: #466660;
  color: white;
  max-width: 50%;
  box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 12px;
  border-radius: 4px;
  word-wrap: break-word;
  font-size: 13px;
}

.message-other {
  border-radius: 4px;
  background-color: white;
  color: black;
  padding: 12px;
  width: 100%;
  box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  font-size: 13px;
}
.message-lenght {
  max-width: 50%;
}

.messages-square {
  padding-top: 0.5%;
  border-radius: 3px;
  width: 24px;
  height: 24px;
}
.item-messages:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.nav-link.active {
  color: var(--guidexPrimary) !important;
  border-color: white white var(--guidexPrimary) white !important;
  border: 5px;
}
#messages-tab-all:hover {
  color: var(--guidexPrimary) !important;
  border-color: white white var(--guidexPrimary) white !important;
}
#messages-tab-unread:hover {
  color: var(--guidexPrimary) !important;
  border-color: white white var(--guidexPrimary) white !important;
}
.nav-tabs {
  border-bottom: 0px !important;
}

.nav > a {
  color: #222b45;
  font-size: 13px;
  font-weight: bold;
}

.message-title {
  padding: 0 1rem 0px 1rem;
}

p.message {
  font-weight: normal;
}
.back-responsive{
  color: #466660;
  font-family: "Montserrat";
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
 
}

.tab-selected{
  color: var(--guidexPrimary) !important;
  font-weight: bold;
  border-bottom: 2px solid var(--guidexPrimary);
padding-left: 20px;
padding-right: 20px;
}