@font-face {
  font-family: 'Hoefler';
  src: local('Hoefler'), url(../../assets/fonts/Hoefler\ Text\ Black.ttf) format('truetype');
}

#dropdownFiltersHome {
  border-bottom: 2px solid #ec7228;
  color: black;
  font-size: 12px;
  font-weight: bold;
}

.search-bar-suggestions-list>.list-group-item {
  background-color: white;
  background-size: cover;
  padding-left: 9%;
  border: 0;
  color: #222b45;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
}

.search-bar-wrapper {
  padding: 70px 0;
}

.search-bar-title {
  margin: auto;
  padding: 10px;
  width: fit-content;
  text-align: center;
}

.search-bar-title p.main-title {
  font-size: 55px;
  color: #ec7228;
  text-shadow: 5px 5px 15px black;
  font-family: "Hoefler";
  font-weight: bolder;
  margin-top: 155px;
}

@media(max-width: 576px) {
  p.main-title {
    font-size: 39px !important;
    margin-top: 60px !important;
  }

  p.main-subtitle {
    font-size: 17px !important;
  }

  .search-bar-title {
    padding: 0px;
  }
}

.search-bar-title p.main-subtitle {
  font-size: 22px;
  color: white;
  font-family: "Roboto";
  font-weight: bold;
}

.search-bar-container {
  width: 80%;
  max-width: 810px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
}

.search-bar-box {
  border-radius: 15px;
  padding: 10px;
}

.search-bar-tab {
  width: 50% !important;
  font-size: 14px;
  font-family: "Roboto";
}

.hunting-tab {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.fishing-tab {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;

}

.search-bar-tab[aria-selected="false"] {
  background-color: #edf1f7;
  color: black;
}

.search-bar-tab-title {
  text-align: center;
}

.search-bar-body-container {
  padding: 20px 10px;
  background-color: white;
  text-align: center;
  width: 100%;
}

.search-bar-body {
  width: 95%;
  margin: auto;
}

.search-option-tab {
  color: black;
  border: none !important;
  font-size: 12px;
  font-weight: bold;
}

.search-option-tab[aria-selected="true"] {
  border: none !important;
  border-bottom: 2px solid var(--guidexPrimary) !important;
}

.search-bar-body input[type="text"] {
  width: 100%;
  padding: 15px;
  background: url(../../assets/icons/search.svg) no-repeat;
  background-position: 2.3%;
  background-color: rgba(48, 49, 51, 0.1);
  padding-left: 45px;
  border: none;
}

.searchbar-wrapper {
  position: relative;
}

.search-navigator {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 17px;
}

.search-bar-body input[type="text"]:focus {
  outline: none;
}

.search-bar-suggestions-list .list-group-item {
  border: none !important;
  color: black !important;
  padding-left: 2%;
  font-family: "roboto";
}

.search-bar-list-title {
  font-family: "roboto";
  color: #8f9bb3 !important;
}