.edit-img{   
    background-color: #555;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;   
}
.btn-settings-edit{
    position: relative;
    top: -30%;
    left: 80%;
  width: 25px !important;
  height:  25px !important;
    background-color: var(--guidexPrimary) !important;
    color: white !important;
}

.wrapper{
    width: 80px !important;
    height: 80px !important;
}
.settings-input-text{
    color: black !important;
    width: 70% !important;
}
.settings-label{
    color: #8992a3;
    font-size: 12px;
    font-weight: bold;
}