.favorite-border{
    border: 3px solid #EC7228;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(48,49,51,0.1);
}
.input-box{
    box-sizing: border-box;
    background-color: rgb(247, 249, 252);
    height: 5.5vh;
    width: 10.5vw;
    border: 1px solid #E4E9F2;
    border-radius: 4px;
    align-items: center;
}
.text-gray{
    color: #8F9BB3;
}
.adv-featured-slider{
    -webkit-appearance: none;
    height: 8px;
    width: 100%;
    border-radius: 6px !important;
    background-color: #E9EAEB;


  }
  .adv-featured-slider:focus-within{
 border: white !important;
    

  }
  input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  .adv-featured-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    box-sizing: border-box;
height: 33px;
width: 33px;
border-radius: 50%;
border: 1px solid #EC7228;
background-color: #EC7228
  }
  .label-range{

  color: #8992A3;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.adv-featured-input{
    width: 26% !important;
}
.bigger-cell{
    font-family: "Roboto";
    font-size: 15px;
    font-weight: bold !important;
    height: 50px !important;

  display: flex;
  align-items: center;
 
 
}
.menu-width{
    width: 27%;
}
.bigger-cell:hover{
 background-color: #EC7228 !important;
 color: white !important;
}
.list-center-element{
    position: relative;
    top: 25%;
}
.btn-select-item{
    background-color: white !important;
    border-radius: 4px !important;
    border:  1px solid #E4E9F2 !important;
    background-color: #f7f9fc !important;
    width: 100% !important;
    height: 50px !important;
    text-align: left !important;
    color: #8F9BB3 !important;
    font-family: "Roboto" !important;
    font-size: 15px !important;
    font-weight: bold !important;
}



.dropdown-toggle-custom::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
  padding-left: 6.6% !important;
    border-top: .0em solid !important  ;
    border-right: .0em solid transparent !important  ;
    border-bottom: 0 !important  ;
    border-left: .0em solid transparent !important  ;
    }
    .icon-wrapper{
        width: 100px !important;
    }
.toggle-icon-right{
    position: absolute;
    bottom: 25%;
    left: 24%;
}
