.btn-gallary {
  width: 300px;
  height: 58px;
  font-size: 20px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
}
.gallery {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 5vw);
  grid-gap: 0;
}

.gallery__img {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1);
  height: 100%;
  object-fit: cover;
  display: block;
  object-position: top;
}

.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 9;

  /** Alternative Syntax **/
  /* grid-column: 1 / span 2;  */
  /* grid-row: 1 / span 2; */
}

.gallery__item--2 {
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 5;

  /** Alternative Syntax **/
  /* grid-column: 3 / span 2;  */
  /* grid-row: 1 / span 2; */
}

.gallery__item--3 {
  grid-column-start: 10;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 5;

  /** Alternative Syntax **/
  /* grid-column: 5 / span 4;
  grid-row: 1 / span 5; */
}

.gallery__item--4 {
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 5;
  grid-row-end: 9;
  /** Alternative Syntax **/
  /* grid-column: 1 / span 4;  */
  /* grid-row: 3 / span 3; */
}

.gallery__item--5 {
  grid-column-start: 10;
  grid-column-end: 13;
  grid-row-start: 5;
  grid-row-end: 9;

  /** Alternative Syntax **/
  /* grid-column: 1 / span 4; */
  /* grid-row: 6 / span 3; */
}

.gallery__item--6 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 9;
  grid-row-end: 13;

  /** Alternative Syntax **/
  /* grid-column: 5 / span 4; */
  /* grid-row: 6 / span 3; */
}

.gallery__item--7 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 9;
  grid-row-end: 13;

  /** Alternative Syntax **/
  /* grid-column: 5 / span 4; */
  /* grid-row: 6 / span 3; */
}
figure {
  margin: 7.5px !important;
}
.gallery__item--8 {
  grid-column-start: 7;
  grid-column-end: 13;
  grid-row-start: 9;
  grid-row-end: 13;
  /** Alternative Syntax **/
  /* grid-column: 5 / span 4; */
  /* grid-row: 6 / span 3; */
}

@media(max-width: 576px){
  .gallery-container{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .img-full-width{
    width: 100vw;
  }
  .btn-gallery-margin{
    margin-top: 1.5rem;
    margin-left: 0px !important;
  }
}
.btn-gallery-margin{
  margin-left: 3rem;
}

@media(min-width: 375px) AND (max-width: 1024px){
  .gallery__item--3,
  .gallery__item--5,
  .gallery__item--6,
  .gallery__item--7,
  .gallery__item--8 {
    display: none;
  }
}

@media(min-width: 375px) AND (max-width: 1024px){
  
  .gallery__item--1 {
    /* grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 9; */
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 13;
  }
  .gallery__item--2 {
    grid-column-start: 9;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 7;
  
    /** Alternative Syntax **/
    /* grid-column: 3 / span 2;  */
    /* grid-row: 1 / span 2; */
  }
  .gallery__item--4 {
    grid-column-start: 9;
  grid-column-end: 13;
  grid-row-start: 7;
  grid-row-end: 13;
  
    /** Alternative Syntax **/
    /* grid-column: 3 / span 2;  */
    /* grid-row: 1 / span 2; */
  }
}
/* .img-mountain{
    min-height: calc(80vh - 271px);
    ;
    min-height: calc(80vh - 271px);  
  } */
.img-bear {
  min-height: calc(76vh - 271px);
}
.img-Deer {
  min-height: calc(74.2vh - 271px);
}
.img-Rocks {
  min-height: calc(122vh - 271px);
}

.img-Field {
  min-height: calc(75.6vh - 271px);
}

/* mas tarde modificar nombres */
