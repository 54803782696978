.font-primary{
  font-family: "Roboto", sans-serif !important;
}
.background-adventures {
  background-color: rgba(70, 102, 102, 0.08);
}

.search-on-map {
  max-width: 1200px;
  margin: 0 auto;
}

.map-search-mapbox {
  overflow-y: auto;
  padding-right: 0 !important;
}

.map-search-expeditions {
  background-color: white;
  overflow-y: auto;
  padding-left: 0 !important;
}
.map-search-div {
  background-color: white;
  overflow-y: auto;
  padding-left: 0.1% !important;
  max-height: 532px;
}

#searchBarMap {
  background-position: 3.3%, 97% !important;
  padding-left: 10.6% !important;
}
.adventures-second-title {
  font-size: 32px;
  margin-bottom: 40px;
}
.adventures-map-container {
  min-height: 648px;
}
@media (max-width: 576px) {
  .adventures-second-title {
    font-size: 24px !important;
  }
  .adventures-map-container {
    padding: 0px 0px !important;
  }
  .show-book-trip-btn {
    display: none !important;
  }
}

@media (min-width: 768px) AND (max-width: 1023px) {
  .hide-book-trip-btn {
    display: none !important;
  }
  .show-book-trip-btn {
    display: block !important;
  }
}

@media (min-width: 1024px) {
  .show-book-trip-btn {
    display: none !important;
  }
}

/* .show-book-trip-btn{
  display: none !important;
} */
.map-trip-cards-container {
  min-height: 648px;
}
.map-trip-cards-container div.map-trip-card:nth-child(2n + 1) {
}

.adventures-first-title {
  font-size: 13px;
  font-weight: bold;
}

.btn-Adventures {
  width: 170px;
  height: 48px;
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: #ec7228 !important;
  color: white !important;
}
.mapbox-container{
  margin-left: 100px;
  margin-right: 100px; 
}
.map-trip-card {
  padding: 0 12%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.map-trip-card:hover {
  background-color: rgba(70, 102, 102, 0.08);
}

.map-card-title {
  font-size: 18px;
  font-weight: bold;
  font-family: "Roboto";
  color: black;
  width: 70%;
}
.map-card-price {
  font-size: 13px;
}
.btn-booking {
  border-color: #ec7228 !important;
  color: #ec7228 !important;
  font-weight: bold !important;
  font-size: 11px !important;
  height: 30px;
  width: 101px;
  background-color: rgba(236, 114, 40, 0.08) !important;
}

.map-search-div::-webkit-scrollbar {
  width: 5px;
  height: 100px !important;
}

.map-search-div::-webkit-scrollbar-track {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  box-shadow: inset 0 0 5px #e9e9e9;
  border-radius: 10px;
}

/* Handle */
.map-search-div::-webkit-scrollbar-thumb {
  height: 100px !important;
  border-radius: 10px;
  background-color: rgba(131, 125, 125, 0.25);
}

.map-pagination {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.map-pagination-title {
  color: #8f9bb3;
  font-family: "Roboto";
  font-size: 11px;
  font-weight: bold;
}
.container-max-width{
  max-width: 1140px;
}