@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');
/* .map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
} */

.mapbox-outter-layer {
  margin: 0;
  padding: 0;
  position: relative;
}

.map-container {
  position: absolute;
  height: 648px;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif !important; 
}

.mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-attrib-button {
  display: none !important;
}

.marker {
  background-image: url("../../assets/icons/location1.svg");
  background-size: cover;
  width: 38px;
  height: 28px;
  border-radius: 25%;
  cursor: pointer;
}

.marker-hunt-trip {
  background-image: url("../../assets/icons/location1.svg");
}

.marker-fish-trip {
  background-image: url("../../assets/icons/location2.svg");
}

.map-popup-button{
  height: 24px;
  font-size: 10px;
}
.map-popup-button p{
  margin: 0 12px;
}
.mapboxgl-popup-close-button{
  font-size: 20px;
}
.map-popup-price-from{
  color:#8F9BB3;
}
.map-popup-price{
  color:#ec7228 !important;
}
.mapboxgl-popup-content{
  width: 255px;
}
.map-popup-content h1 {
  font-size: 13px;
  font-weight: bolder;
  line-height: 16px;
  color: #222b45;
}

.map-popup-content p.guide-company {
  font-size: 10px;
  color: #ec7228;
}

.map-popup-content p.price {
  font-size: 13px;
  font-weight: bold;
  line-height: 24px;
  color: #222b45;
}
