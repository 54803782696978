.acc-details-form-container {
  float: left;
  padding: 40px !important;
  background-color: white;
  box-shadow: 1px 1px 5px 5px #dddddd;
}

.acc-details-form-input {
  width: 80% !important;
  color: #222b45 !important;
  font-family: Roboto;
  font-size: 15px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 24px !important;
}
.acc-details-info {
  height: 24px;
  width: 137px;
  color: #222b45;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.icon-password {
  top: 23%;
  right: 22%;
  cursor: pointer;
  z-index: 1001;
}
.btn-acc-details-delete {
  border-color: #ec7228 !important;
  color: #ec7228 !important;
  height: 40px;
  width: 150px;
  font-weight: bold !important;
  font-size: 13px !important;
  background-color: rgba(236, 114, 40, 0.1) !important;
}
.disabled-input:hover {
  cursor: no-drop;
}
.adv-settings {
  color: #222b45;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.btn-save-portal {
  height: 40px;
  padding: 9px 26px !important;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}
