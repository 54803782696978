.modal-xl {
    max-width: 840px !important;
}
.padding-right-image{
    padding-right: 4.6% !important;
    padding-left: 1% !important;
}
.gallery-padding{
    padding-bottom: 2% !important;
}
.list-components ul li::before {
    content: none;

    color: black;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
.list-components ul li{
    margin-bottom: 2%;
    padding-left: 0 !important;
}


@media (min-width: 1800px) {
    .padding-right-image{
        padding-right: 3.7% !important;
        padding-left: 1.8% !important;
    }
}