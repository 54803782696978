.popular-color {
  color: #466660;
  font-family: "Roboto";
}
.popular-padding {
  background-color: #f6f6f6;
  max-width: 1140px !important;
}

.icon-Included path {
  fill: #466660;
}

h2.popular-text{
  font-size: 26px;
}