.outfitter-match {
  padding-bottom: 1%;
  padding-top: 3%;
  background-color: #f1f3f3;
}

.matches {
  padding-left: 10% !important;
  padding-right: 10% !important;
}

.charter-match-title {
  color: white;
  font-weight: bold;
}

.outfitter-match-title {
  color: #466660;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}

.see-all-guide-btn {
  padding: 15px 22.5px !important;
  margin: 20px auto 40px auto;
  display: block !important;
}

@media(max-width: 576px) {
  .charter-match-title {
    margin-top: 90px;
    margin-bottom: 0px !important;
  }

  #imageBackgroundTopoAlt {
    padding-bottom: 20px;
  }
}

@media(min-width: 768px) AND (max-width: 1023px) {
  .charter-match-title {
    margin-top: 50px;
    margin-bottom: 10px !important;
  }

  #imageBackgroundTopoAlt {
    padding-bottom: 20px;
  }
}

@media(min-width: 1024px) AND (max-width: 1100px) {
  .charter-match-title {
    margin-top: 20px;
    margin-bottom: 0px !important;
  }
}