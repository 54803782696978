.trips span {
  font-weight: bold;
}

/* .trips{
    padding-left: 9% !important;
    padding-right:9% !important;
} */

.main-img {
  min-width: 100%;
}

.image-gallery-bullets {
  bottom: -20px !important;
}

.image-gallery {
  margin-bottom: 40px;
}

.image-gallery-bullets .image-gallery-bullet.active {
  background-color: #000000 !important;
}

.img-clas {
  min-height: 100%;
}

.oval {
  background-color: rgba(0, 0, 0, 0.38) !important;
  width: 4px;
  height: 4px;
  padding: 2.5px !important;
  box-shadow: unset !important;
  border-radius: 100% !important;
}

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 500px !important;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.trip-information {
  padding-top: 5%;
}

.carrousel-img {
  margin-left: 10px;
}

.trips-guide-logo {
  float: left;
}

.trips-pdp-title {
  color: #466660;
  font-family: 'Montserrat';
  font-size: 38px;
  font-weight: bold;
}

.trips-guide-rating {
  margin-left: 6% !important;
}

.starRatingsText {
  color: #222b45;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.guideName {
  color: #ec7228;
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.pdpTitle {
  color: #466660;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
}

.pjson p {
  color: #222b45;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.pdp-price-original {
  color: #ec7228;
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}

.pdp-price-discount {
  color: #8f9bb3;
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: line-through;
}

.contact-info-title {
  color: #222b45;
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}

.contact-info-body {
  color: #222b45;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.contact-info-login {
  color: #f4541d;
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  cursor: pointer;
}

.featured-icon {
  position: absolute;
  z-index: 1000;
  top: 16px;
  padding: 7px 10px;
  max-width: 114px;
  border-radius: 0 4px 4px 0;
  background-color: #ec7228;
}

.featured-icon>* {
  color: white;
  color: #ffffff;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
}

.featured-icon svg {
  margin-top: -1px;
}

.image-gallery-svg,
image-gallery-icon {
  height: 60px !important;
}

.description-max-scroll{
  text-align: justify;
  max-height: 200px;
  overflow-y: scroll;
}