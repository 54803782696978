.btn-guidex-link-header {
    color: white !important;
    font-size: 15px !important;
    font-weight: bold !important;
}

.dropdown-menu-header {
    width: 100% !important;
}

.navbar-light .navbar-toggler {
    border: unset !important;
    color: white !important;
}

#responsive-navbar-nav {
    position: relative;
    z-index: 20000;
}

.navbar-light .navbar-toggler-icon {

    background-image: url('../assets/icons/menu.svg') !important
}

.dropdown-item-header {
    font-weight: bold !important;
}


@media(min-width: 768px) {
    #responsive-navbar-nav {
        display: contents !important;
    }
}

.mobile-user-dropdown {
    background-color: white;
    padding: 15px 0;
}