#imageBacgroundColorado {
  background-image: url(../../assets/pngs/Colorado.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  background-color: #466660;
  min-height: 273px;
}
#imageBacgroundMaine {
  background-image: url(../../assets/pngs/Maine.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: 273px;
}
#imageBacgroundWyoming {
  background-image: url(../../assets/pngs/Wyoming.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: 273px;
}
#imageBacgroundFlorida {
  background-image: url(../../assets/pngs/Florida.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: 273px;
}
#imageBacgroundAlaska {
  background-image: url(../../assets/pngs/Alaska.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: 273px;
}
.TDestinations-title p{
  font-size: 13px;
}
.TDestinations-title h1{
  font-size: 32px;
}
.btn-TDestinations {
  min-width: 200px;
  font-size: 16px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: #ec7228 !important;
  color: white !important;
}

.text-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 36px;
  font-weight: bold;
}

.zoom-img:hover{
  transform: scale(1.01);
  z-index: 999999999;
}

.container-td-cards {
  margin-top: 60px;
}

@media(max-width: 576px){
  .container-td-cards{
    margin-top: 15px;
  }
  .TDestinations-title h1{
    font-size: 24px;
  }
}