
.profile-title{
 
  color: #466660;
  font-family: "Montserrat";
  font-size: 40px !important;
  font-weight: bold;
  text-align: left;
}
.profile-subtitle{
  color:#ec7228;
  font-weight: bold;
  font-family: "Roboto";
  font-weight: bold;
}
.general-details-title {
  color: #8992a3;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: bolder;
}
.general-details-subtitle {
  color: #222b45;
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}
.btn-general-details {
  width: 180px;
  height: 40px;
  font-size: 14px !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: var(--guidexPrimary) !important;
  color: white !important;
}
.btn-general-details-responsive {
  width: 130px;
  height: 50px;
  font-size: 14px !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: var(--guidexPrimary) !important;
  color: white !important;
}
.general-details-body {
  color: #222b45;
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: pre-line;
}
.btn-general-adresses {
  width: 170px;
  height: 40px;
  font-size: 14px !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: var(--guidexPrimary) !important;
  color: white !important;
}

.general-details-container {
  padding-right: 12% !important;
}

.btn-contact-list-add {
  border-color: #ec7228 !important;
  color: #ec7228 !important;
  height: 35px;
  width: 138px;
  font-weight: bold !important;
  font-size: 14px !important;
  background-color: rgba(236, 114, 40, 0.1) !important;
}

.btn-cancel {
  border-color: #ec7228 !important;
  color: #ec7228 !important;
  width: 105px;
  font-weight: bold !important;
  font-size: 14px !important;
  background-color: rgba(236, 114, 40, 0.1) !important;
}

.btn-save {
  width: 150px;
  height: 45px;
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: var(--guidexPrimary) !important;
  color: white !important;
}

.btn-td-cancel {
  border-color: #ec7228 !important;
  color: #ec7228 !important;
  height: 34px;
  width: 75px;
  font-weight: bold !important;
  font-size: 12px !important;
  background-color: rgba(236, 114, 40, 0.1) !important;
}

.btn-td-save {
  width: 120px;
  height: 34px;
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: var(--guidexPrimary) !important;
  color: white !important;
}
.trips-title {
  color: #466660;
  font-family: "Montserrat";
  font-size: 36px;
  font-weight: bold;
}

.btn-save-exit {
  border-color: #ec7228 !important;
  color: #ec7228 !important;
  height: 43px;
  width: 120px;
  font-weight: bold !important;
  font-size: 14px !important;
  background-color: rgba(236, 114, 40, 0.1) !important;
}
.btn-continue {
  width: 105px;
  height: 43px;
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: var(--guidexPrimary) !important;
  color: white !important;
}
.orders-title {
  position: absolute;
}
.reviews-title {
  color: #466660;
  font-family: "Montserrat";
  font-size: 36px;
  font-weight: bold;
}

.btn-request-review {
  height: 45px;
  width: 180px;
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "Roboto", sans-serif !important;
  background-color: var(--guidexPrimary) !important;
  color: white !important;
}
