.card-button{
    color: #6D7278;
    font-weight: bold;
    font-size: 20px !important;
}
.card-image-size{
    /* max-height:   16.475750577367204vh !important;
  max-width: 7.0588235294117645vw !important; */
  padding-top: 4% !important;
  padding-bottom: 4% !important;
  height:  12 !important;
  width: 12 !important;
  border-radius: 4px;
}
.card-separator {
    border-left: 3px solid #e9eaed;
    height: 10vh !important;
    margin-left: 10%;
    margin-right: 10%;
  
  
}
.card-image-container{
   padding-left: 0 !important;
    background-color: white;
}