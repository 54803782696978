.map-geolocation-wrapper{
  position: relative;
  height: 500px;
  display: block;
}

.map-geolocation-zip{
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  z-index: 20;
}

.map-geolocation-zip input{
  width: 90%;
  z-index: 30;
}

.my-location-button {
  cursor: pointer;
  position: absolute;
  left: 95%;
  top: 17px;
  z-index: 40;
}

.map-geolocation-container{
  position: absolute;
  height: 500px;
  width: 100%;
}

.marker-geolocation {
  background-size: cover;
  width: 30px;
  height: 37px;
  cursor: pointer;
  background-image: url("../../../../assets/icons/Pin\ Cursor.svg");
}

.zoom-in-geolocation{
  position: absolute;
  bottom: 90px;
  right: 30px;
  width: 50px;
  height: 50px;
  z-index: 50;
  cursor: pointer;
}

.zoom-out-geolocation{
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  z-index: 50;
  cursor: pointer;
}