.approve-guide-container2{
    width: 70% !important;
    background-color: white;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 2% !important;
    padding-bottom: 2% !important;
    box-shadow: 2px 3px 10px 5px rgba(0, 0, 0, 0.24);
}
.btn-approve-guide-approved{
    background-color: var(--guidexPrimary) !important;
  color: white !important;
 width: 260px !important;
  font-weight: bold !important;
  font-family: "Roboto";
}
.link-underline{
    text-decoration: underline !important;
}
.label{
    color: #8992a3;
    font-size: 16px;
    font-weight: bold;
}
.license{
    color:var(--guidexPrimary) ;
    font-weight: bold;
    font-size: 16;
}