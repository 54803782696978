@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.btn-owner {
  border-color: #ec7228 !important;
  color: #ec7228 !important;
  height: 48px;
  width: 130px;
  font-weight: bold !important;
  font-size: 16px !important;
  background-color: rgba(236, 114, 40, 0.1) !important;
}

.owner-info li {
  font-weight: bold;
  margin-top: 10px;
}

.owner-info ul {
  font-size: 15px;
  padding-left: 15px;
  font-family: "Roboto" !important;
}

.owner-info img {
  min-width: 100%;
}

.max-widht-card {
  max-width: 75vw;
}

@media(max-width: 576px){
  .max-widht-card {
    max-width: 97vw;
  }
}
