.btn-carrousel {
  border-color: #ec7228;
  color: #ec7228;
  font-size: 11px !important;
  width: 100px;
  height: 30px;
  background-color: #ec722814 !important;
}

.text-carrousel-card {
  color: #466660;
  font-family: "Roboto", sans-serif !important;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  max-height: 190px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

.carrousel-card {
  min-height: 341px;
  box-shadow: 0 16px 24px 0 rgba(48,49,51,0.2);
}

.btn-carrousel {
  border-color: var(--guidexPrimary) !important;
  color: var(--guidexPrimary) !important;
  font-size: 11px !important;
  width: 100px;
  height: 30px;
  background-color: #ec722814 !important;
}

.text-carrousel-guideinfo {
  position: absolute !important;
  bottom: 30px;
  left: 1.5rem;
  font-family: "Roboto", sans-serif !important;
}
.carousel-control-next-icon {
  transform: scaleX(-1);
  background-image: url(../../assets/icons/arrow.svg) !important;
  height: 80px !important;
  width: 40px !important;
}
.carousel-control-prev-icon {
  background-image: url(../../assets/icons/arrow.svg) !important;
  height: 80px !important;
  width: 40px !important;
}

@media (min-width: 576px) {
}
/* 
  // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}
/* 
  // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #ratingsHome .carousel-control-next {
    right: -9vw !important;
  }
  #ratingsHome .carousel-control-prev {
    left: -9vw !important;
  }
}
/* 
  // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
