.pdp {
  padding-top: 80px;
}
.back {
  padding-top: 2%;
  margin-bottom: -22px;

  color: #222b45;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  cursor: pointer;
}
.trips {
  background-color: #f6f6f6;
  background-size: cover;
  padding-left: 9% !important;
  padding-left: 9% !important;
}

.carrousel-pdp {
  background-color: #f6f6f6;
  background-size: cover;
}
.captainContainer {
  background-color: #e9eaeb;
  background-size: cover;
  padding-left: 9% !important;
  padding-left: 9% !important;
}
