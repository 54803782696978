.col-order-table{
    padding-left: 10% !important;
    padding-right: 10% !important;
}
.order-table{
    font-size: 22px !important;
}
.order-table-border{
    border-bottom:1px solid  #8F9BB3!important ;
 
}
.budget-row{
    padding-left: 9% !important;
}

.data-table-extensions-filter{
    float:right !important;
    height: 52px;
    width: 327px;
    background-color: white!important;
}
.data-table-extensions > .data-table-extensions-filter > .icon {
    background-image: url("../../../../assets/icons/search.svg") !important;
    padding-top: 14%;
    padding-left: 10% !important;
    margin-left: 5% !important;
}
.data-table-extensions > .data-table-extensions-filter > .filter-text {
    border-bottom: none !important;
    padding-top: 4% !important;
}

.table-order-details td,
.table-order-details th{
    width: 39% !important;
}
.order-total{
    padding-left: 72% !important;
}