

/* Multiple Accounts */

ul.list-group{
  list-style-type: none !important;
}
ul.list-group li::before{
  display: none;
}
li.list-group-item{
  background-color: white !important;
}
li.separator{
  border-bottom: 1px solid rgba(0,0,0,0.1) !important;
}