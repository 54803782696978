@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.quote-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif !important;
}

.container-text-about-page{
  position: relative;
  display:flex;
  align-items: center;
  justify-content: center;
}
.content-text-about-page{
  position: absolute;
  max-width: 75vw;
  top:220px;
  color:white;
  font-family: "Montserrat", sans-serif !important;
  font-size:30px;
  font-weight:700;
  text-align:center;
}
.image-max-size{
  max-height: 466px;
}
.quote-title-h1 {
  font-size: 35px !important;
  font-weight: bold;
  color: white;
}
#imageBackgroundQuote {
  background-image: url(../../assets/pngs/MissionStatement.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(70, 102, 96, 0.4);
  min-height: calc(110vh - 271px - 76px);
}
